import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import "../styles/app.scss";
import imgSrc from "../assets/images/fraud-prevention.jpg";
export default function Home() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Function to check if the viewport width is less than a certain value
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < 768); // Example breakpoint: 768px
    };

    // Initial check
    checkIsMobile();

    // Listen to window resize event
    window.addEventListener("resize", checkIsMobile);

    // Cleanup function to remove the resize event listener
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);
  return (
    <>
      <Layout
        title="Fraud Webinars"
        metaDescription="HSBC Intermediaries Webinars for Fraud Prevention"
        disableWrapper={true}
      >
        <div></div>
        <div
          style={{
            backgroundImage: `url(${imgSrc})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "auto",
            height: "33vw",
            width: "100%",
            maxHeight: "300px",
          }}
        ></div>
        <div
          style={{
            padding: "25px 0",
          }}
        >
          <div className="wrapper" style={{ display: "block" }}>
            <h1 className="page-title">HSBC UK Fraud webinars </h1>
            <p>
              To register for one of the webinars, please click on your preferred option below and enter your details.
            </p>
          </div>
          ]
          <div
            className="wrapper"
            style={{
              gap: "20px",
              width: "100%",
              display: "flex",
              alignItems: "stretch",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <div
              className="accordion__body "
              style={{
                backgroundColor: "white",
                width: isMobile ? "100%" : "calc(50% - 10px)",
                maxWidth: "100%",
              }}
            >
              <h3>
                <strong>CPD approved 2024 Mortgage Fraud Prevention</strong>
              </h3>

              <table>
                <tbody>
                  <tr>
                    <td>
                      <p>
                        <a href="https://hsbc.zoom.us/webinar/register/WN_1e8ELNA3RJ-f0yh3z-gBwg">
                          4:00pm, Monday 22<sup>nd</sup> July
                        </a>
                      </p>
                    </td>
                    <td>
                      <p>
                        <a href="https://hsbc.zoom.us/webinar/register/WN_HHOUnID7SE6sg5ZzrGsuKw">
                          10:00am, Friday 30<sup>th</sup> August
                        </a>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <a href="https://hsbc.zoom.us/webinar/register/WN_dbKzMXTXQb23K7shPfZpqw">
                          1:00pm, Monday 9<sup>th</sup> September
                        </a>
                      </p>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              className="accordion__body "
              style={{
                backgroundColor: "white",
                width: isMobile ? "100%" : "calc(50% - 10px)",
                maxWidth: "100%",
              }}
            >
              <h3>
                <strong>CPD approved Introduction to Mortgage Fraud</strong>
              </h3>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <p>
                        <a href="https://hsbc.zoom.us/webinar/register/WN_n-tw4uq6QaCwdeJmbOlptg">
                          1:00pm, Friday 26<sup>th</sup> July
                        </a>
                      </p>
                    </td>
                    <td>
                      <p>
                        <a href="https://hsbc.zoom.us/webinar/register/WN_yyNzSTjqQWGW2ZOAExmiBA">
                          9:00am, Wednesday 7<sup>th</sup> August
                        </a>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <a href="https://hsbc.zoom.us/webinar/register/WN_IGcDozgHTZGl9ZB1Rz6u2Q">
                          10:30am, Thursday 19<sup>th</sup> September
                        </a>
                      </p>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
